<template>
  <div>
    <task-list-add-new
      :is-add-new-task-sidebar-active.sync="isAddNewTaskSidebarActive"
      :project-options="projectOptions"
      :task-status-options="taskStatusOptions"
      :task-type-options="taskTypeOptions"
      :task-priority-options="taskPriorityOptions"
      :sprint="true"
      :sprintProjectId="sprintData.projectId"
      :sprintId="sprintData.id"
    />
    <!-- Alert: No item found -->
    <b-alert variant="danger" :show="sprintData === undefined">
      <h4 class="alert-heading">{{ $t("message.errorLoadingData") }}</h4>
      <div class="alert-body">
        {{ $t("message.errorSprint") }}
        <b-link class="alert-link" :to="{ name: 'apps-team-list' }">
          {{ $t("message.listOfSprint") }}
        </b-link>
        {{ $t("message.forOtherSprint") }}
      </div>
    </b-alert>

    <b-overlay
      variant="transparent"
      opacity="1.00"
      blur="6px"
      :show="showOverlay"
      rounded="sm"
      no-wrap
    ></b-overlay>

    <template v-if="!sprintData.tenant">
      <div class="text-center my-17">
        <b-spinner class="align-middle"></b-spinner>
        <strong> {{ $t("message.load") }}...</strong>
      </div>
    </template>

    <template v-else>
      <div>
        <form-wizard
          ref="wizard"
          color="#7367F0"
          :title="null"
          :subtitle="null"
          layout="vertical"
          class="wizard-vertical mb-3"
          hideButtons
          :startIndex="indexOfSprint"
        >
          <!-- account datails tab -->
          <tab-content :title="$t('message.sprints.general')">
            <b-row>
              <b-col md="8">
                <b-form-group label-for="nameSprints">
                  <template v-slot:label>
                    {{ $t("message.tableHeader.name") }}
                  </template>
                  <b-form-input
                    v-model="sprintData.subject"
                    label="name"
                    label-for="nameSprints"
                  />
                </b-form-group>
              </b-col>

              <b-col md="2">
                <b-form-group label="Ínicio " label-for="createdDate">
                  <template v-slot:label>
                    {{ $t("message.date.start") }}
                  </template>
                  <flat-pickr
                    id="created-date"
                    v-model="sprintData.startDate"
                    class="form-control"
                    :config="flatConfig"
                  />
                </b-form-group>
              </b-col>

              <b-col md="2">
                <b-form-group label="Fim" label-for="endDate">
                  <template v-slot:label>
                    {{ $t("message.date.end") }}
                  </template>
                  <flat-pickr
                    id="end-date"
                    v-model="sprintData.endDate"
                    class="form-control"
                    :config="flatConfig"
                  />
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col md="12">
                <b-form-group label="Descrição" label-for="descriptionSprints">
                  <template v-slot:label>
                    {{ $t("message.projects.description") }}
                  </template>
                  <quill-editor
                    id="title"
                    style="margin-bottom: 52px; height: 200px"
                    :options="editorOption"
                    v-model="sprintData.description"
                  />
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col md="7">
                <b-form-group label-for="projectId">
                  <template v-slot:label>
                    {{ $t("message.projects.project") }}
                  </template>
                  <v-select
                    :options="projectOptions"
                    :reduce="(val) => val.value"
                    :clearable="false"
                    v-model="sprintData.projectId"
                    input-id="sprint-projects"
                    disabled
                  />
                </b-form-group>
              </b-col>
              <b-col md="5">
                <b-form-group label="Estado" label-for="projectId">
                  <template v-slot:label>
                    {{ $t("message.tableHeader.status") }}
                  </template>
                  <v-select
                    v-model="sprintData.status"
                    :options="sprintStatusOptions"
                    :reduce="(val) => val.value"
                    :clearable="false"
                    input-id="sprint-status"
                    disabled
                  />
                </b-form-group>
              </b-col>
            </b-row>

            <b-row>
              <b-col md="12">
                <b-form-group label="Tipo" label-for="projectId">
                  <template v-slot:label>
                    {{ $t("message.projects.tasks") }}
                  </template>
                  <v-select
                    :options="taskOptions"
                    v-model="sprintData.tasks"
                    :reduce="(val) => val.value"
                    multiple
                    :clearable="false"
                  >
                    <template v-slot:option="option">
                      <div v-html="option.label" style="padding: 2px 0"></div>
                    </template>
                    <template v-slot:selected-option="option">
                      <div v-html="option.label"></div>
                    </template>
                  </v-select>
                </b-form-group>
              </b-col>
            </b-row>
          </tab-content>

          <!-- Sprint Planning -->
          <tab-content title="Sprint Planning">
            <b-row>
              <b-col cols="12" class="mb-2">
                <h5 class="mb-0">Sprint Planning</h5>
                <small class="text-muted">{{
                  $t("message.sprints.fillDescript")
                }}</small>
              </b-col>
            </b-row>

            <b-row>
              <b-col md="12">
                <b-form-group>
                  <quill-editor
                    id="title"
                    style="margin-bottom: 52px; height: 200px"
                    :options="editorOption"
                    v-model="sprintData.planningDesc"
                  />
                </b-form-group>
              </b-col>
            </b-row>
          </tab-content>

          <!-- Running -->
          <tab-content title="Running">
            <b-row>
              <b-col cols="12" class="mb-2">
                <h5 class="mb-0">Running</h5>
                <small class="text-muted">{{
                  $t("message.sprints.fillDescript")
                }}</small>
              </b-col>
            </b-row>

            <b-row>
              <b-col md="12">
                <b-form-group>
                  <quill-editor
                    id="title"
                    style="margin-bottom: 52px; height: 200px"
                    :options="editorOption"
                    v-model="sprintData.runningDesc"
                  />
                </b-form-group>
              </b-col>
            </b-row>
          </tab-content>

          <!-- Sprint Review -->
          <tab-content title="Sprint Review">
            <b-row>
              <b-col cols="12" class="mb-2">
                <h5 class="mb-0">Sprint Review</h5>
                <small class="text-muted">{{
                  $t("message.sprints.fillDescript")
                }}</small>
              </b-col>
            </b-row>

            <b-row>
              <b-col md="12">
                <b-form-group>
                  <quill-editor
                    id="title"
                    style="margin-bottom: 52px; height: 200px"
                    :options="editorOption"
                    v-model="sprintData.reviewDesc"
                  />
                </b-form-group>
              </b-col>
            </b-row>
          </tab-content>

          <!-- Sprint Retrospective -->
          <tab-content title="Sprint Retrospective">
            <b-row>
              <b-col cols="12" class="mb-2">
                <h5 class="mb-0">Sprint Retrospective</h5>
                <small class="text-muted">{{
                  $t("message.sprints.fillDescript")
                }}</small>
              </b-col>
            </b-row>

            <b-row>
              <b-col md="12">
                <b-form-group>
                  <quill-editor
                    id="title"
                    style="margin-bottom: 52px; height: 200px"
                    :options="editorOption"
                    v-model="sprintData.retrospectiveDesc"
                  />
                </b-form-group>
              </b-col>
            </b-row>
          </tab-content>

          <!-- Sprint End -->
          <tab-content :title="$t('message.sprints.sprintEnd')">
            <b-card
              text-variant="center"
              class="card card-congratulations"
              v-if="sprintData.finishDate"
            >
              <br /><br /><br /><br /><br /><br />
              <!-- images -->
              <b-img
                :src="require('@/assets/images/elements/decore-left.png')"
                class="congratulations-img-left"
              />
              <b-img
                :src="require('@/assets/images/elements/decore-right.png')"
                class="congratulations-img-right"
              />
              <!--/ images -->

              <b-avatar variant="primary" size="90" class="shadow mb-2">
                <feather-icon size="28" icon="AwardIcon" />
              </b-avatar>
              <h1 class="mb-1 mt-50 text-white">
                {{ $t("message.sprints.sprintEnding") }},
              </h1>
              <b-card-text class="m-auto w-75">
                {{ $t("message.sprints.sprintCreateText") }}
                <strong>{{ localeDate(sprintData.startDate) }}</strong>
                {{ $t("message.sprints.sprintEndText") }}
                <strong>{{
                  localeDate(getFormattedDate(sprintData.finishDate))
                }}</strong
                >.
              </b-card-text>
            </b-card>
            <b-card
              text-variant="center"
              class="card card-congratulations"
              v-else
            >
              <div class="text-center my-15" v-if="loading === true">
                <b-spinner class="align-middle"></b-spinner>
                <strong> {{ $t("message.load") }}...</strong>
              </div>
            </b-card>
          </tab-content>

          <div class="mt-1 mb-2 text-right" v-if="$refs.wizard">
            <b-button
              class="mr-1"
              variant="warning"
              @click="isAddNewTaskSidebarActive = true"
              v-if="
                restrictions('button_new_task') &&
                $refs.wizard.activeTabIndex === 0
              "
            >
              <span class="text-nowrap">{{
                $t("message.tasks.createNewTask")
              }}</span>
            </b-button>
            <b-button class="mr-2" variant="success" @click="updateSprint()">
              {{ $t("message.sprints.save") }}
            </b-button>
            <b-button
              variant="outline-secondary"
              @click="goBack()"
              v-if="$refs.wizard.activeTabIndex > 0"
            >
              <feather-icon icon="ChevronLeftIcon" />
            </b-button>
            <b-button
              variant="primary"
              @click="goNext()"
              v-if="$refs.wizard.activeTabIndex < 4"
            >
              <feather-icon icon="ChevronRightIcon" />
            </b-button>
            <b-button
              variant="primary"
              @click="goEnd()"
              v-else-if="
                $refs.wizard.activeTabIndex === 4 &&
                sprintData.status != 'Finished'
              "
            >
              {{ $t("message.sprints.endSprint") }}
            </b-button>
            <b-button
              variant="primary"
              @click="goNext()"
              v-else-if="$refs.wizard.activeTabIndex != 5"
            >
              <feather-icon icon="ChevronRightIcon" />
            </b-button>
          </div>
          <div class="mt-1 mb-2 text-right" v-else>
            <b-button
              class="mr-1"
              variant="warning"
              @click="isAddNewTaskSidebarActive = true"
              v-if="restrictions('button_new_task')"
            >
              <span class="text-nowrap">{{
                $t("message.tasks.createNewTask")
              }}</span>
            </b-button>
            <b-button class="ml-2" variant="success" @click="updateSprint()">
              {{ $t("message.sprints.save") }}
            </b-button>
            <b-button variant="primary" @click="goNext()">
              <feather-icon icon="ChevronRightIcon" />
            </b-button>
          </div>
        </form-wizard>
      </div>
    </template>

    <b-modal
      id="modal-next-confirmation"
      modal-class="modal-danger"
      centered
      title="Sprint"
      ok-title="Sim"
      cancel-title="Cancelar"
      @ok="acceptNextPage()"
    >
      <b-card-text>
        {{ $t("message.sprints.modalSetop") }}
      </b-card-text>
    </b-modal>

    <b-modal
      id="modal-end-confirmation"
      modal-class="modal-danger"
      centered
      title="Sprint"
      ok-title="Sim"
      cancel-title="Cancelar"
      @ok="endSprint()"
    >
      <b-card-text>
        {{ $t("message.sprints.endSprintText") }}
      </b-card-text>
    </b-modal>
  </div>
</template>

<script>
import {
  BTab,
  BTabs,
  BCard,
  BAlert,
  BLink,
  BButton,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BFormCheckbox,
  BForm,
  BFormSelect,
  BFormTextarea,
  BCardText,
  BFormSpinbutton,
  BFormInvalidFeedback,
  BProgress,
  BProgressBar,
  BSpinner,
  BImg,
  BOverlay,
  BAvatar,
} from "bootstrap-vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { FormWizard, TabContent } from "vue-form-wizard";
import axios from "@axios";
import "vue-form-wizard/dist/vue-form-wizard.min.css";
import URL_API from "@core/utils/env";
import getHeader from "@core/utils/auth";
import vSelect from "vue-select";
import router from "@/router";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import flatPickr from "vue-flatpickr-component";
import dateInputEdit from "@/@core/utils/dateInputEdit";
import store from "@/store";
import TaskListAddNew from "../../task/task-list/TaskListAddNew.vue";
import AccessControl from "@core/utils/access-control";
import { quillEditor } from "vue-quill-editor";
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";

export default {
  components: {
    FormWizard,
    TabContent,
    BTab,
    BTabs,
    BCard,
    BAlert,
    BLink,
    BButton,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BForm,
    BFormSelect,
    vSelect,
    BFormTextarea,
    BCardText,
    flatPickr,
    BFormSpinbutton,
    BProgress,
    BProgressBar,
    BFormInvalidFeedback,
    ValidationProvider,
    ValidationObserver,
    BSpinner,
    BImg,
    BAvatar,
    quillEditor,
    BOverlay,
    TaskListAddNew,
  },

  data() {
    return {
      sprintData: [],
      projectOptions: [],
      sprintStatusOptions: [],
      taskOptions: [],
      userData: store.state.user.userData,

      flatConfig: { altFormat: "d/m/Y", altInput: true, format: "d/m/Y" },
      userData: store.state.user.userData,

      nextStep: false,
      indexOfSprint: 0,

      showOverlay: false,

      loading: false,

      isAddNewTaskSidebarActive: false,
      taskStatusOptions: [],
      taskTypeOptions: [],
      taskPriorityOptions: [],

      editorOption: {
        placeholder: "",
        modules: {
          toolbar: [
            ["bold", "italic", "underline"],
            ["code-block"],
            [{ list: "ordered" }, { list: "bullet" }],
            [{ header: [1, 2, 3, 4, 5, 6, false] }],
            [{ color: [] }, { background: [] }],
          ],
          syntax: {
            highlight: (text) => hljs.highlightAuto(text).value,
          },
        },
      },
    };
  },

  watch: {
    "$i18n.locale": function (newVal, oldVal) {
      this.flatConfig = dateInputEdit(newVal);
    },
  },

  mounted() {
    if (this.$i18n.locale) {
      this.flatConfig = dateInputEdit(this.$i18n.locale);
    }

    this.$root.$on("newListTaskSprint", (newListTaskSprint) => {
      this.refreshData();
    });
  },

  async created() {
    this.showOverlay = true;
    await this.getData();
    await this.getTaskList();

    var projectList = [];
    var projectId = [];

    await axios
      .get(`${URL_API}project/select_list`, {
        headers: getHeader(this.userData),
      })
      .then((response) => {
        for (let i = 0; i < response.data.length; i++) {
          const element = response.data[i].name;
          const id = response.data[i].id;
          projectList.push(element);
          projectId.push(id);
          this.projectOptions.push({
            label: projectList[i],
            value: projectId[i],
          });
        }
      });

    var optionsList = [];
    await axios
      .get(`${URL_API}task_status`, { headers: getHeader(this.userData) })
      .then((response) => {
        for (let i = 0; i < response.data.length; i++) {
          const element = response.data[i].status;
          optionsList.push(element);
          this.taskStatusOptions.push({
            label: optionsList[i],
            value: optionsList[i],
          });
        }
      });

    var typeList = [];
    await axios
      .get(`${URL_API}task_type`, { headers: getHeader(this.userData) })
      .then((response) => {
        for (let i = 0; i < response.data.length; i++) {
          const element = response.data[i].type;
          typeList.push(element);
          this.taskTypeOptions.push({ label: typeList[i], value: typeList[i] });
        }
      });

    var priorityList = [];
    await axios
      .get(`${URL_API}task_priority`, { headers: getHeader(this.userData) })
      .then((response) => {
        for (let i = 0; i < response.data.length; i++) {
          const element = response.data[i].priority;
          priorityList.push(element);
          this.taskPriorityOptions.push({
            label: priorityList[i],
            value: priorityList[i],
          });
        }
      });

    var optionsList = [];
    await axios
      .get(`${URL_API}sprint_status`, { headers: getHeader(this.userData) })
      .then((response) => {
        for (let i = 0; i < response.data.length; i++) {
          const element = response.data[i].status;
          optionsList.push(element);
          this.sprintStatusOptions.push({
            label: optionsList[i],
            value: optionsList[i],
          });
        }
      });
      setTimeout(() => {
      this.showOverlay = false;
    }, 1000)
  },

  methods: {
    async refreshData() {
      this.loading = true;
      this.sprintData = [];
      await this.getData();
      await this.getTaskList();
    },

    async getData() {
      await axios({
        method: "get",
        url: `${URL_API}sprint/${router.currentRoute.params.id}`,
        headers: getHeader(this.userData),
      })
        .then((response) => {
          this.sprintData = response.data;

          if (response.data.status === "Not Started") {
            this.indexOfSprint = 0;
          } else if (response.data.status === "Sprint Planning") {
            this.indexOfSprint = 1;
          } else if (response.data.status === "Running") {
            this.indexOfSprint = 2;
          } else if (response.data.status === "Sprint Review") {
            this.indexOfSprint = 3;
          } else if (response.data.status === "Sprint Retrospective") {
            this.indexOfSprint = 4;
          } else if (response.data.status === "Finished") {
            this.indexOfSprint = 5;
          }
        })
        .catch((error) => {
          if (error.response.status === 404) {
            this.sprintData = undefined;
          }
        });
    },

    getFormattedDate(date) {
      var d = new Date(date);
      return d.toISOString().slice(0, 10);
    },

    restrictions(value) {
      return AccessControl(value);
    },

    goNext() {
      if (this.sprintData.status === "Not Started") {
        this.$root.$emit("bv::show::modal", "modal-next-confirmation");
      } else if (this.sprintData.status === "Sprint Planning") {
        if (this.$refs.wizard.activeTabIndex < 1) {
          this.$refs.wizard.nextTab();
        } else {
          this.$root.$emit("bv::show::modal", "modal-next-confirmation");
        }
      } else if (this.sprintData.status === "Running") {
        if (this.$refs.wizard.activeTabIndex < 2) {
          this.$refs.wizard.nextTab();
        } else {
          this.$root.$emit("bv::show::modal", "modal-next-confirmation");
        }
      } else if (this.sprintData.status === "Sprint Review") {
        if (this.$refs.wizard.activeTabIndex < 3) {
          this.$refs.wizard.nextTab();
        } else {
          this.$root.$emit("bv::show::modal", "modal-next-confirmation");
        }
      } else if (this.sprintData.status === "Sprint Retrospective") {
        if (this.$refs.wizard.activeTabIndex < 4) {
          this.$refs.wizard.nextTab();
        } else {
          this.$root.$emit("bv::show::modal", "modal-next-confirmation");
        }
      } else if (this.sprintData.status === "Finished") {
        this.$refs.wizard.nextTab();
      }
    },

    goBack() {
      this.$refs.wizard.prevTab();
    },

    goEnd() {
      this.$root.$emit("bv::show::modal", "modal-end-confirmation");
    },

    endSprint() {
      var date = new Date();

      this.sprintData.status = "Finished";
      this.sprintData.finishDate = date;
      this.updateSprint();
      this.$refs.wizard.nextTab();
    },

    acceptNextPage() {
      this.$refs.wizard.nextTab();
      var date = new Date();

      if (this.$refs.wizard.activeTabIndex === 0) {
        this.sprintData.status = "Not Started";
      } else if (this.$refs.wizard.activeTabIndex === 1) {
        this.sprintData.status = "Sprint Planning";
        this.sprintData.planningDate = date;
      } else if (this.$refs.wizard.activeTabIndex === 2) {
        this.sprintData.status = "Running";
        this.sprintData.runningDate = date;
      } else if (this.$refs.wizard.activeTabIndex === 3) {
        this.sprintData.status = "Sprint Review";
        this.sprintData.reviewDate = date;
      } else if (this.$refs.wizard.activeTabIndex === 4) {
        this.sprintData.status = "Sprint Retrospective";
        this.sprintData.retrospectiveDate = date;
      }

      this.updateSprint();
    },

    updateSprint() {
      axios({
        method: "put",
        url: `${URL_API}sprint/${this.sprintData.id}`,
        headers: getHeader(this.userData),
        data: this.sprintData,
      }).then(() => {
        this.$toast({
          component: ToastificationContent,
          position: "top-right",
          props: {
            title: this.$i18n.t("message.toast.saved"),
            icon: "CheckIcon",
            variant: "success",
            text: this.$i18n.t("message.toast.updatedB"),
          },
        });
      });
    },

    formSubmitted() {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Form Submitted",
          icon: "EditIcon",
          variant: "success",
        },
      });
    },

    async getTaskList() {
      this.loading = true;

      this.taskOptions = [];
      await axios
        .get(
          `${URL_API}task/sprint/${this.sprintData.id}/${this.sprintData.projectId}`,
          {
            headers: getHeader(this.userData),
          }
        )
        .then((response) => {
          for (var i = 0; i < response.data.length; i++) {
            if (response.data[i].status === "Finalizado") {
              this.taskOptions.push({
                label:
                  response.data[i].subject +
                  " - " +
                  `<span style="padding: 1px; background: green; border-radius: 0.35rem; color: black;">` +
                  this.$i18n.t("message.tasks.finished") +
                  `</span>`,
                value: response.data[i].id,
              });
            } else if (response.data[i].status === "Rejeitado") {
              this.taskOptions.push({
                label:
                  response.data[i].subject +
                  " - " +
                  `<span style="padding: 1px; background: red; border-radius: 0.35rem; color: black;">` +
                  this.$i18n.t("message.tasks.rejected") +
                  `</span>`,
                value: response.data[i].id,
              });
            } else {
              this.taskOptions.push({
                label:
                  response.data[i].subject +
                  " - " +
                  `<span style="padding: 1px; background: yellow; border-radius: 0.35rem; color: black;">` +
                  this.$i18n.t("message.opportunities.pending") +
                  `</span>`,
                value: response.data[i].id,
              });
            }
          }
          this.loading = false;
        });
    },

    localeDate(value) {
      if (value) {
        return this.$i18n.d(
          new Date(value.split("-")),
          "short",
          this.$i18n.locale
        );
      }
      return this.$i18n.t("message." + "noDate");
    },

    openProjectDetails(projectId) {
      this.$router.push({
        name: "apps-projects-details",
        params: { id: projectId },
      });
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-wizard.scss";
@import "@core/scss/vue/libs/vue-select.scss";
.my-17 {
  margin-top: 17.2rem;
  margin-bottom: 17.2rem;
}
.my-15 {
  margin-top: 15.2rem;
  margin-bottom: 15.2rem;
}
</style>
